import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MediaCard, MediaCardThumb, MediaCardBody, MediaCardTitle } from "../MediaStyle";
import styled from "styled-components";

export const ProductDesc = styled.div`
  color:#000;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 28px;
  margin-bottom:15px;
  @media(min-width:1200px){        
    font-size: 16px;
    line-height: 28px;
    margin-bottom:20px;
  }
  p {
    font-size: 14px;
    line-height: 30px;
    @media(min-width:1200px){        
      font-size: 16px;
      line-height: 32px;
    }
  }
`

const SearchCard = ({ data }) => {
  return (
    <MediaCard className='media-card' >
      <MediaCardThumb className='media-card-thumb'>
        <GatsbyImage image={getImage(data.image.gatsbyImageData)} alt={data.name}  />
      </MediaCardThumb>
      <MediaCardBody className='media-card-body'>
        <MediaCardTitle className='media-card-title'>
          {data.name}
        </MediaCardTitle>
        <ProductDesc>
          <p>{data.metaDescription}</p>
        </ProductDesc>
      </MediaCardBody>
    </MediaCard>
  )
}

export default SearchCard