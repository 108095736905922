import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from "../layouts";
import Seo from "../components/seo"
import { Section, Container, SectionPageTitle } from "../components/Section"
import { Nodata, NodataSubTitle, NodataDesc, NodataContainer } from "../components/Nodata"
import SearchIcon from "../components/Icons/SearchIcon"
import SearchCloseIcon from "../components/Icons/SearchCloseIcon"
import { useSearch } from '../useSearch';
import SearchData from '../components/SearchData';

const SearchList = styled.div`
    margin:0;
`

const SearchForm = styled.div`
  background-color: #EEF8FF;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  margin:0 auto 30px auto;
  @media (min-width:1200px) {
    margin:0 auto 40px auto;
  }
  @media (min-width:1600px) {
    margin:0 auto 60px auto;
  }
  .input-icon{
    > .form-control{
        @media (min-width: 1200px){
            padding: 10px 15px;
        }
        @media (min-width: 1600px){
            padding: 15px;
        }

        &::placeholder {
            color:#004485;
        }
        &:focus,
        &:active {
            &::placeholder {
            color:#004485;
            }
        }
    }
    .icon {
        width:45px;
        > svg{
          fill:#4815D6;
          width:20px;
        }    
    }
    &.right {
        > .form-control{
            padding-right: 45px;
            padding-left: 15px;
        }
    }
  }
`

const SearchPage = ({ location }) => {
  const [query, setQuery] = useState(location?.state?.searchKey);
  const results = useSearch(query);

  const handleChange = (e) => {
    setQuery(e.target.value);
  }

  return (
    <Layout hideSearch>
      <Seo title="Search Result" description="Search Result" />
      <Section ept="80px" epb="80px" xpt="40px" xpb="40px" pt="30px" pb="30px" bgColor="#fff" className="section-search" >
        <Container maxWidth="1170px">
          <SectionPageTitle textAlign="center">Search Results</SectionPageTitle>
          <SearchForm className='search-form'>
            <div className="input-icon right">
              <input
                className="form-control"
                type="text"
                placeholder="Search..."
                autoComplete='off'
                id="search"
                name="search"
                value={query ? query : ""}
                onChange={(e) => handleChange(e)}
              />
              <span className="icon"><SearchIcon /></span>
              <span className="icon" style={{ display: "none" }}><SearchCloseIcon /></span>
            </div>
          </SearchForm>
          <SearchList className="search-list">
            {results.length > 0 ? (
              <SearchData items={results} />
            ) : (
              <Nodata>
                <NodataContainer>
                  <NodataSubTitle className='h3'>No Results Found</NodataSubTitle>
                  <NodataDesc><p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.</p></NodataDesc>
                </NodataContainer>
              </Nodata>
            )}

          </SearchList>

        </Container>
      </Section>
    </Layout>
  )
}

export default SearchPage
