import styled from 'styled-components'

export const MediaCard = styled.div`
	max-width: 860px;
	margin: 30px auto;
	display: flex;
`
export const MediaCardBody = styled.div`
	background-color:transparent;
    position: relative;
		
`
export const MediaCardThumb = styled.div`
    overflow:hidden;
    position:relative;
`

export const MediaCardTitle = styled.div`
	font-family: "Chakra Petch", sans-serif;
	font-weight: 700;
	margin-bottom:5px;
	font-size: 20px;
	line-height: 34px;
    > a{
        color:#062C44;
        &:hover, &:focus, &:active{
            color:#000;
        }
    }
	@media (min-width: 992px) {
		font-size: 24px;
		line-height: 36px;
	}
	@media (min-width: 1200px) {
		font-size: 32px;
		line-height: 40px;
	}
`
export const MediaCardDescription = styled.div`
	margin:0;
`